import React from "react"
import { Link } from "gatsby"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import SEO from "Components/core/SEO"

const ProjectsPage: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Projects" description="Portfolio Projects by Liam Myles" />
    <h1>Projects</h1>
    <p>
      I love to code! And I do my best to share what I have done, most of my
      code lives in dusty repos owned by corporates. But these are the things I
      built for the pure love of it
    </p>
    <h2>Tinkering</h2>
    <p>
      This is everything I have managed to do while having a job! Even I
      don&apos;t know where I found the time 😅
    </p>
    <Link to="/projects/tinkering">Peruse my tinkering?</Link>
    <h2>University Projects</h2>
    <p>
      Between 2011 and 2015 I got to build flash games, websites and animations
      all as I learned about multimedia and slowly discovered my passion for
      code.
    </p>
    <Link to="/projects/university">Examine my university work?</Link>

    <h2>In The Works</h2>
    <p>
      Right now as I write this in June 2021 I have two projects in the works.
    </p>
    <p>
      One is a component library that will hopefully contain all the nifty
      components I have built for myself over the years.
    </p>
    <p>
      The other is a collaboration with a professor in America who also has an
      interest in playing with polygons.
    </p>
  </StandardLayout>
)

export default ProjectsPage
